import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'
import auth from './modules/auth';
import lang from './modules/lang';
import ACTION_TYPES from "./action-types";
import MUTATION_TYPES from "./mutation-types";
import { v4 as uuidv4 } from 'uuid';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        new VuexPersistence({
            reducer: rootState => ({
                auth: rootState.auth,
            }),
        }).plugin,
    ],
    modules: {
        auth,
        lang,
    },
    state: {
        alerts: [],
        deliveryType: null,
        deliveryRule: null,
        deliveryRuleType: null,
        workingDaySwitch: null,
        navItems: [
            { title: 'Delivery Charge', icon: 'mdi-cash-multiple', link: {name: 'dashboard.deliverycharges'}, permission: 'DELIVERYCHARGE_READ_WEB' },
            { title: 'Excluded Dates', icon: 'mdi-cash-multiple', link: {name: 'dashboard.delivery-excluded-dates'}},
            { title: 'Stock Notices', icon: 'mdi-truck-delivery', link: {name: 'dashboard.stock-notice'}, permission: 'STOCKNOTICE_READ_WEB' },
            { title: 'Postcode Groupings', icon: 'mdi-post', link: {name: 'dashboard.postcode-groupings'}, permission: 'POSTCODEGROUPING_READ_WEB' },
            { title: 'Product Groupings', icon: 'mdi-gift-open', link: {name: 'dashboard.product-groupings'}, permission: 'PRODUCTGROUPING_READ_WEB' },
            { title: 'Build Services', icon: 'mdi-wrench', link: {name: 'dashboard.buildservices'}, permission: 'BUILDSERVICE_READ_WEB' },
            { title: 'Delivery Rate Requests', icon: 'mdi-cash-multiple', link: {name: 'dashboard.delivery-rate-requests'}, permission: 'DELIVERYCHARGE_READ_WEB' },
        ],
    },
    mutations: {
        [MUTATION_TYPES.ADD_ALERT](state, value) {
            state.alerts.push(value);
        },
        [MUTATION_TYPES.REMOVE_ALERT](state, alertUuid) {
            state.alerts = state.alerts.filter((alert) => {
                return alert.uuid !== alertUuid;
            });
        },
        [MUTATION_TYPES.UPDATE_CURRENT_DELIVERY_TYPE](state, value) {
            state.deliveryType = value;
        },
        [MUTATION_TYPES.UPDATE_CURRENT_DELIVERY_RULE](state, value) {
            state.deliveryRule = value;
        },
        [MUTATION_TYPES.UPDATE_CURRENT_DELIVERY_RULE_TYPE](state, value) {
            state.deliveryRuleType = value;
        },
        [MUTATION_TYPES.UPDATE_WORKING_DAY_SWITCH](state, value) {
            state.workingDaySwitch = value;
        },
    },
    actions: {
        [ACTION_TYPES.ADD_ALERT.NAME]({ commit }, alertData) {
            const newAlert = Object.assign({
                uuid: uuidv4(),
                visible: true,
                timeout: 2000,
            }, alertData);
            commit(MUTATION_TYPES.ADD_ALERT, newAlert);
            setTimeout(() => {
                commit(MUTATION_TYPES.REMOVE_ALERT, newAlert.uuid);
            }, newAlert.timeout + 500)
        },
        [ACTION_TYPES.UPDATE_DELIVERY_TYPE.NAME]({ commit }, deliveryType) {
            commit(MUTATION_TYPES.UPDATE_CURRENT_DELIVERY_TYPE, deliveryType);
        },
        [ACTION_TYPES.UPDATE_DELIVERY_RULE.NAME]({ commit }, deliveryRule) {
            commit(MUTATION_TYPES.UPDATE_CURRENT_DELIVERY_RULE, deliveryRule);
        },
        [ACTION_TYPES.UPDATE_DELIVERY_RULE_TYPE.NAME]({ commit }, deliveryRuleType) {
            commit(MUTATION_TYPES.UPDATE_CURRENT_DELIVERY_RULE_TYPE, deliveryRuleType);
        },
        [ACTION_TYPES.UPDATE_WORKING_DAY_SWITCH.NAME]({ commit }, workingDaySwitch) {
            commit(MUTATION_TYPES.UPDATE_WORKING_DAY_SWITCH, workingDaySwitch);
        },
    },
});

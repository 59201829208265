export default {
    ADD_ALERT: {
        NAME: 'add_alert',
        PATH: 'add_alert',
    },
    UPDATE_DELIVERY_TYPE: {
        NAME: 'UPDATE_DELIVERY',
        PATH: 'UPDATE_DELIVERY',
    },
    UPDATE_DELIVERY_RULE: {
        NAME: 'UPDATE_DELIVERY_RULE',
        PATH: 'UPDATE_DELIVERY_RULE',
    },
    UPDATE_DELIVERY_RULE_TYPE: {
        NAME: 'UPDATE_DELIVERY_RULE_TYPE',
        PATH: 'UPDATE_DELIVERY_RULE_TYPE',
    },
    UPDATE_WORKING_DAY_SWITCH: {
        NAME: 'UPDATE_WORKING_DAY_SWITCH',
        PATH: 'UPDATE_WORKING_DAY_SWITCH',
    }
};
